
import AvatarImage from "@/components/AvatarImage.vue";
import { PropType } from "vue";

interface Item {
  name: string;
  note: string;
  avatar?: {
    seedID: string;
    imageID: string;
  };
}

import { defineComponent } from "vue";
export default defineComponent({
  name: "CustomDropDown",
  components: { AvatarImage },
  emits: ["change"],
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array as PropType<(Item | any)[]>,
      required: true,
    },
    error: {
      type: String,
      required: false,
    },
    defaultId: {
      type: [Number, String],
      default: null,
    },
    namePath: {
      type: String,
      default: "name",
    },
    validMessage: {
      type: String,
      required: false,
    },
    IdPath: {
      type: String,
      required: true,
    },
    defaultText: {
      type: String,
      default: "Select Item",
    },
  },
  data() {
    return {
      focused: true,
      openDropDown: false,
      selectedId: this.defaultId,
    };
  },
  computed: {
    selectedItem(): any {
      if (this.selectedId === null) return undefined;
      return this.items.find((i) => i[this.IdPath] === this.selectedId);
    },
  },
  methods: {
    clickOutside() {
      this.openDropDown = false;
    },
    itemClick(id: number) {
      this.openDropDown = false;
      this.selectedId = id;
      this.$emit("change", id);
    },
  },
});
