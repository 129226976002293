export const genders = [
  { emoji: "👦🏻", name: "Male", code: "M" },
  { emoji: "👧🏼", name: "Female", code: "F" },
  { emoji: "?", name: "Other", code: "O" },
  { emoji: "😶", name: "Rather not say", code: "no" },
];
export const ageGroups = [
  { emoji: "", name: "14-16", code: "14-16" },
  { emoji: "", name: "17-19", code: "17-19" },
  { emoji: "", name: "20 or above", code: "20+" },
  { emoji: "😶", name: "Rather not say", code: "no" },
];
export const continents = [
  { name: "Europe", emoji: "🍃", code: "EU" },
  { name: "Asia", emoji: "🍜", code: "AS" },
  { name: "North America", emoji: "🍟", code: "NA" },
  { name: "South America", emoji: "🌴", code: "SA" },
  { name: "Africa", emoji: "🐘", code: "AF" },
  { name: "Oceania", emoji: "🌊", code: "OC" },
  { emoji: "😶", name: "Rather not say", code: "no" },
];
export const countries = [
  { name: "Andorra", emoji: "🇦🇩", code: "EU" },
  { name: "United Arab Emirates", emoji: "🇦🇪", code: "AS" },
  { name: "Afghanistan", emoji: "🇦🇫", code: "AS" },
  { name: "Antigua and Barbuda", emoji: "🇦🇬", code: "NA" },
  { name: "Anguilla", emoji: "🇦🇮", code: "NA" },
  { name: "Albania", emoji: "🇦🇱", code: "EU" },
  { name: "Armenia", emoji: "🇦🇲", code: "AS" },
  { name: "Angola", emoji: "🇦🇴", code: "AF" },
  { name: "Argentina", emoji: "🇦🇷", code: "SA" },
  { name: "Austria", emoji: "🇦🇹", code: "EU" },
  { name: "Aruba", emoji: "🇦🇼", code: "NA" },
  { name: "Åland", emoji: "🇦🇽", code: "EU" },
  { name: "Azerbaijan", emoji: "🇦🇿", code: "AS" },
  { name: "Bosnia and Herzegovina", emoji: "🇧🇦", code: "EU" },
  { name: "Barbados", emoji: "🇧🇧", code: "NA" },
  { name: "Bangladesh", emoji: "🇧🇩", code: "AS" },
  { name: "Belgium", emoji: "🇧🇪", code: "EU" },
  { name: "Burkina Faso", emoji: "🇧🇫", code: "AF" },
  { name: "Bulgaria", emoji: "🇧🇬", code: "EU" },
  { name: "Bahrain", emoji: "🇧🇭", code: "AS" },
  { name: "Burundi", emoji: "🇧🇮", code: "AF" },
  { name: "Benin", emoji: "🇧🇯", code: "AF" },
  { name: "Saint Barthélemy", emoji: "🇧🇱", code: "NA" },
  { name: "Bermuda", emoji: "🇧🇲", code: "NA" },
  { name: "Brunei", emoji: "🇧🇳", code: "AS" },
  { name: "Bolivia", emoji: "🇧🇴", code: "SA" },
  { name: "Bonaire", emoji: "🇧🇶", code: "NA" },
  { name: "Brazil", emoji: "🇧🇷", code: "SA" },
  { name: "Bahamas", emoji: "🇧🇸", code: "NA" },
  { name: "Bhutan", emoji: "🇧🇹", code: "AS" },
  { name: "Botswana", emoji: "🇧🇼", code: "AF" },
  { name: "Belarus", emoji: "🇧🇾", code: "EU" },
  { name: "Belize", emoji: "🇧🇿", code: "NA" },
  { name: "Canada", emoji: "🇨🇦", code: "NA" },
  { name: "Cocos [Keeling] Islands", emoji: "🇨🇨", code: "AS" },
  { name: "Democratic Republic of the Congo", emoji: "🇨🇩", code: "AF" },
  { name: "Central African Republic", emoji: "🇨🇫", code: "AF" },
  { name: "Republic of the Congo", emoji: "🇨🇬", code: "AF" },
  { name: "Switzerland", emoji: "🇨🇭", code: "EU" },
  { name: "Ivory Coast", emoji: "🇨🇮", code: "AF" },
  { name: "Chile", emoji: "🇨🇱", code: "SA" },
  { name: "Cameroon", emoji: "🇨🇲", code: "AF" },
  { name: "China", emoji: "🇨🇳", code: "AS" },
  { name: "Colombia", emoji: "🇨🇴", code: "SA" },
  { name: "Costa Rica", emoji: "🇨🇷", code: "NA" },
  { name: "Cuba", emoji: "🇨🇺", code: "NA" },
  { name: "Cape Verde", emoji: "🇨🇻", code: "AF" },
  { name: "Curacao", emoji: "🇨🇼", code: "NA" },
  { name: "Christmas Island", emoji: "🇨🇽", code: "AS" },
  { name: "Cyprus", emoji: "🇨🇾", code: "EU" },
  { name: "Czech Republic", emoji: "🇨🇿", code: "EU" },
  { name: "Germany", emoji: "🇩🇪", code: "EU" },
  { name: "Djibouti", emoji: "🇩🇯", code: "AF" },
  { name: "Denmark", emoji: "🇩🇰", code: "EU" },
  { name: "Dominica", emoji: "🇩🇲", code: "NA" },
  { name: "Dominican Republic", emoji: "🇩🇴", code: "NA" },
  { name: "Algeria", emoji: "🇩🇿", code: "AF" },
  { name: "Ecuador", emoji: "🇪🇨", code: "SA" },
  { name: "Estonia", emoji: "🇪🇪", code: "EU" },
  { name: "Egypt", emoji: "🇪🇬", code: "AF" },
  { name: "Western Sahara", emoji: "🇪🇭", code: "AF" },
  { name: "Eritrea", emoji: "🇪🇷", code: "AF" },
  { name: "Spain", emoji: "🇪🇸", code: "EU" },
  { name: "Ethiopia", emoji: "🇪🇹", code: "AF" },
  { name: "Finland", emoji: "🇫🇮", code: "EU" },
  { name: "Falkland Islands", emoji: "🇫🇰", code: "SA" },
  { name: "Faroe Islands", emoji: "🇫🇴", code: "EU" },
  { name: "France", emoji: "🇫🇷", code: "EU" },
  { name: "Gabon", emoji: "🇬🇦", code: "AF" },
  { name: "United Kingdom", emoji: "🇬🇧", code: "EU" },
  { name: "Grenada", emoji: "🇬🇩", code: "NA" },
  { name: "Georgia", emoji: "🇬🇪", code: "AS" },
  { name: "French Guiana", emoji: "🇬🇫", code: "SA" },
  { name: "Guernsey", emoji: "🇬🇬", code: "EU" },
  { name: "Ghana", emoji: "🇬🇭", code: "AF" },
  { name: "Gibraltar", emoji: "🇬🇮", code: "EU" },
  { name: "Greenland", emoji: "🇬🇱", code: "NA" },
  { name: "Gambia", emoji: "🇬🇲", code: "AF" },
  { name: "Guinea", emoji: "🇬🇳", code: "AF" },
  { name: "Guadeloupe", emoji: "🇬🇵", code: "NA" },
  { name: "Equatorial Guinea", emoji: "🇬🇶", code: "AF" },
  { name: "Greece", emoji: "🇬🇷", code: "EU" },
  { name: "Guatemala", emoji: "🇬🇹", code: "NA" },
  { name: "Guinea-Bissau", emoji: "🇬🇼", code: "AF" },
  { name: "Guyana", emoji: "🇬🇾", code: "SA" },
  { name: "Hong Kong", emoji: "🇭🇰", code: "AS" },
  { name: "Honduras", emoji: "🇭🇳", code: "NA" },
  { name: "Croatia", emoji: "🇭🇷", code: "EU" },
  { name: "Haiti", emoji: "🇭🇹", code: "NA" },
  { name: "Hungary", emoji: "🇭🇺", code: "EU" },
  { name: "Indonesia", emoji: "🇮🇩", code: "AS" },
  { name: "Ireland", emoji: "🇮🇪", code: "EU" },
  { name: "Israel", emoji: "🇮🇱", code: "AS" },
  { name: "Isle of Man", emoji: "🇮🇲", code: "EU" },
  { name: "India", emoji: "🇮🇳", code: "AS" },
  { name: "British Indian Ocean Territory", emoji: "🇮🇴", code: "AS" },
  { name: "Iraq", emoji: "🇮🇶", code: "AS" },
  { name: "Iran", emoji: "🇮🇷", code: "AS" },
  { name: "Iceland", emoji: "🇮🇸", code: "EU" },
  { name: "Italy", emoji: "🇮🇹", code: "EU" },
  { name: "Jersey", emoji: "🇯🇪", code: "EU" },
  { name: "Jamaica", emoji: "🇯🇲", code: "NA" },
  { name: "Jordan", emoji: "🇯🇴", code: "AS" },
  { name: "Japan", emoji: "🇯🇵", code: "AS" },
  { name: "Kenya", emoji: "🇰🇪", code: "AF" },
  { name: "Kyrgyzstan", emoji: "🇰🇬", code: "AS" },
  { name: "Cambodia", emoji: "🇰🇭", code: "AS" },
  { name: "Comoros", emoji: "🇰🇲", code: "AF" },
  { name: "Saint Kitts and Nevis", emoji: "🇰🇳", code: "NA" },
  { name: "North Korea", emoji: "🇰🇵", code: "AS" },
  { name: "South Korea", emoji: "🇰🇷", code: "AS" },
  { name: "Kuwait", emoji: "🇰🇼", code: "AS" },
  { name: "Cayman Islands", emoji: "🇰🇾", code: "NA" },
  { name: "Kazakhstan", emoji: "🇰🇿", code: "AS" },
  { name: "Laos", emoji: "🇱🇦", code: "AS" },
  { name: "Lebanon", emoji: "🇱🇧", code: "AS" },
  { name: "Saint Lucia", emoji: "🇱🇨", code: "NA" },
  { name: "Liechtenstein", emoji: "🇱🇮", code: "EU" },
  { name: "Sri Lanka", emoji: "🇱🇰", code: "AS" },
  { name: "Liberia", emoji: "🇱🇷", code: "AF" },
  { name: "Lesotho", emoji: "🇱🇸", code: "AF" },
  { name: "Lithuania", emoji: "🇱🇹", code: "EU" },
  { name: "Luxembourg", emoji: "🇱🇺", code: "EU" },
  { name: "Latvia", emoji: "🇱🇻", code: "EU" },
  { name: "Libya", emoji: "🇱🇾", code: "AF" },
  { name: "Morocco", emoji: "🇲🇦", code: "AF" },
  { name: "Monaco", emoji: "🇲🇨", code: "EU" },
  { name: "Moldova", emoji: "🇲🇩", code: "EU" },
  { name: "Montenegro", emoji: "🇲🇪", code: "EU" },
  { name: "Saint Martin", emoji: "🇲🇫", code: "NA" },
  { name: "Madagascar", emoji: "🇲🇬", code: "AF" },
  { name: "Macedonia", emoji: "🇲🇰", code: "EU" },
  { name: "Mali", emoji: "🇲🇱", code: "AF" },
  { name: "Myanmar [Burma]", emoji: "🇲🇲", code: "AS" },
  { name: "Mongolia", emoji: "🇲🇳", code: "AS" },
  { name: "Macao", emoji: "🇲🇴", code: "AS" },
  { name: "Martinique", emoji: "🇲🇶", code: "NA" },
  { name: "Mauritania", emoji: "🇲🇷", code: "AF" },
  { name: "Montserrat", emoji: "🇲🇸", code: "NA" },
  { name: "Malta", emoji: "🇲🇹", code: "EU" },
  { name: "Mauritius", emoji: "🇲🇺", code: "AF" },
  { name: "Maldives", emoji: "🇲🇻", code: "AS" },
  { name: "Malawi", emoji: "🇲🇼", code: "AF" },
  { name: "Mexico", emoji: "🇲🇽", code: "NA" },
  { name: "Malaysia", emoji: "🇲🇾", code: "AS" },
  { name: "Mozambique", emoji: "🇲🇿", code: "AF" },
  { name: "Namibia", emoji: "🇳🇦", code: "AF" },
  { name: "Niger", emoji: "🇳🇪", code: "AF" },
  { name: "Nigeria", emoji: "🇳🇬", code: "AF" },
  { name: "Nicaragua", emoji: "🇳🇮", code: "NA" },
  { name: "Netherlands", emoji: "🇳🇱", code: "EU" },
  { name: "Norway", emoji: "🇳🇴", code: "EU" },
  { name: "Nepal", emoji: "🇳🇵", code: "AS" },
  { name: "Oman", emoji: "🇴🇲", code: "AS" },
  { name: "Panama", emoji: "🇵🇦", code: "NA" },
  { name: "Peru", emoji: "🇵🇪", code: "SA" },
  { name: "Philippines", emoji: "🇵🇭", code: "AS" },
  { name: "Pakistan", emoji: "🇵🇰", code: "AS" },
  { name: "Poland", emoji: "🇵🇱", code: "EU" },
  { name: "Saint Pierre and Miquelon", emoji: "🇵🇲", code: "NA" },
  { name: "Puerto Rico", emoji: "🇵🇷", code: "NA" },
  { name: "Palestine", emoji: "🇵🇸", code: "AS" },
  { name: "Portugal", emoji: "🇵🇹", code: "EU" },
  { name: "Paraguay", emoji: "🇵🇾", code: "SA" },
  { name: "Qatar", emoji: "🇶🇦", code: "AS" },
  { name: "Réunion", emoji: "🇷🇪", code: "AF" },
  { name: "Romania", emoji: "🇷🇴", code: "EU" },
  { name: "Serbia", emoji: "🇷🇸", code: "EU" },
  { name: "Russia", emoji: "🇷🇺", code: "EU" },
  { name: "Rwanda", emoji: "🇷🇼", code: "AF" },
  { name: "Saudi Arabia", emoji: "🇸🇦", code: "AS" },
  { name: "Seychelles", emoji: "🇸🇨", code: "AF" },
  { name: "Sudan", emoji: "🇸🇩", code: "AF" },
  { name: "Sweden", emoji: "🇸🇪", code: "EU" },
  { name: "Singapore", emoji: "🇸🇬", code: "AS" },
  { name: "Saint Helena", emoji: "🇸🇭", code: "AF" },
  { name: "Slovenia", emoji: "🇸🇮", code: "EU" },
  { name: "Svalbard and Jan Mayen", emoji: "🇸🇯", code: "EU" },
  { name: "Slovakia", emoji: "🇸🇰", code: "EU" },
  { name: "Sierra Leone", emoji: "🇸🇱", code: "AF" },
  { name: "San Marino", emoji: "🇸🇲", code: "EU" },
  { name: "Senegal", emoji: "🇸🇳", code: "AF" },
  { name: "Somalia", emoji: "🇸🇴", code: "AF" },
  { name: "Suriname", emoji: "🇸🇷", code: "SA" },
  { name: "South Sudan", emoji: "🇸🇸", code: "AF" },
  { name: "São Tomé and Príncipe", emoji: "🇸🇹", code: "AF" },
  { name: "El Salvador", emoji: "🇸🇻", code: "NA" },
  { name: "Sint Maarten", emoji: "🇸🇽", code: "NA" },
  { name: "Syria", emoji: "🇸🇾", code: "AS" },
  { name: "Swaziland", emoji: "🇸🇿", code: "AF" },
  { name: "Turks and Caicos Islands", emoji: "🇹🇨", code: "NA" },
  { name: "Chad", emoji: "🇹🇩", code: "AF" },
  { name: "Togo", emoji: "🇹🇬", code: "AF" },
  { name: "Thailand", emoji: "🇹🇭", code: "AS" },
  { name: "Tajikistan", emoji: "🇹🇯", code: "AS" },
  { name: "Turkmenistan", emoji: "🇹🇲", code: "AS" },
  { name: "Tunisia", emoji: "🇹🇳", code: "AF" },
  { name: "Turkey", emoji: "🇹🇷", code: "AS" },
  { name: "Trinidad and Tobago", emoji: "🇹🇹", code: "NA" },
  { name: "Taiwan", emoji: "🇹🇼", code: "AS" },
  { name: "Tanzania", emoji: "🇹🇿", code: "AF" },
  { name: "Ukraine", emoji: "🇺🇦", code: "EU" },
  { name: "Uganda", emoji: "🇺🇬", code: "AF" },
  { name: "United States", emoji: "🇺🇸", code: "NA" },
  { name: "Uruguay", emoji: "🇺🇾", code: "SA" },
  { name: "Uzbekistan", emoji: "🇺🇿", code: "AS" },
  { name: "Vatican City", emoji: "🇻🇦", code: "EU" },
  { name: "Saint Vincent and the Grenadines", emoji: "🇻🇨", code: "NA" },
  { name: "Venezuela", emoji: "🇻🇪", code: "SA" },
  { name: "British Virgin Islands", emoji: "🇻🇬", code: "NA" },
  { name: "U.S. Virgin Islands", emoji: "🇻🇮", code: "NA" },
  { name: "Vietnam", emoji: "🇻🇳", code: "AS" },
  { name: "Kosovo", emoji: "🇽🇰", code: "EU" },
  { name: "Yemen", emoji: "🇾🇪", code: "AS" },
  { name: "Mayotte", emoji: "🇾🇹", code: "AF" },
  { name: "South Africa", emoji: "🇿🇦", code: "AF" },
  { name: "Zambia", emoji: "🇿🇲", code: "AF" },
  { name: "Zimbabwe", emoji: "🇿🇼", code: "AF" },
  { name: "American Samoa", emoji: "🇦🇸", code: "OC" },
  { name: "Australia", emoji: "🇦🇺", code: "OC" },
  { name: "Cook Islands", emoji: "🇨🇰", code: "OC" },
  { name: "Fiji", emoji: "🇫🇯", code: "OC" },
  { name: "Micronesia", emoji: "🇫🇲", code: "OC" },
  { name: "Guam", emoji: "🇬🇺", code: "OC" },
  { name: "Kiribati", emoji: "🇰🇮", code: "OC" },
  { name: "Marshall Islands", emoji: "🇲🇭", code: "OC" },
  { name: "Northern Mariana Islands", emoji: "🇲🇵", code: "OC" },
  { name: "New Caledonia", emoji: "🇳🇨", code: "OC" },
  { name: "Norfolk Island", emoji: "🇳🇫", code: "OC" },
  { name: "Nauru", emoji: "🇳🇷", code: "OC" },
  { name: "Niue", emoji: "🇳🇺", code: "OC" },
  { name: "New Zealand", emoji: "🇳🇿", code: "OC" },
  { name: "French Polynesia", emoji: "🇵🇫", code: "OC" },
  { name: "Papua New Guinea", emoji: "🇵🇬", code: "OC" },
  { name: "Pitcairn Islands", emoji: "🇵🇳", code: "OC" },
  { name: "Palau", emoji: "🇵🇼", code: "OC" },
  { name: "Solomon Islands", emoji: "🇸🇧", code: "OC" },
  { name: "Tokelau", emoji: "🇹🇰", code: "OC" },
  { name: "East Timor", emoji: "🇹🇱", code: "OC" },
  { name: "Tonga", emoji: "🇹🇴", code: "OC" },
  { name: "Tuvalu", emoji: "🇹🇻", code: "OC" },
  { name: "U.S. Minor Outlying Islands", emoji: "🇺🇲", code: "OC" },
  { name: "Vanuatu", emoji: "🇻🇺", code: "OC" },
  { name: "Wallis and Futuna", emoji: "🇼🇫", code: "OC" },
  { name: "Samoa", emoji: "🇼🇸", code: "OC" },
  { emoji: "😶", name: "Rather not say", code: "no" },
];
